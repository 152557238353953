export function useGetDateAgo(date: string): string {
  // Parse the date as UTC
  const givenDateUtc = new Date(date + "Z");

  // Get the current date in UTC
  const currentDateUtc = new Date();

  // Convert both dates to Melbourne time
  const currentDate = new Date(
    currentDateUtc.getTime() + useGetTimeZoneOffset() * 60 * 60 * 1000
  );

  // Calculate the difference in milliseconds
  const diffTime = currentDate.getTime() - givenDateUtc.getTime();

  // Calculate hours and minutes
  const hours = Math.floor(diffTime / (1000 * 60 * 60));
  const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));

  // Generate the time difference string
  let result = "";

  if (hours > 0) {
    result += `${hours} ${hours === 1 ? "hour" : "hours"}`;
    if (minutes > 0) {
      result += ` and ${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
    }
  } else if (minutes > 0) {
    result += `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  } else {
    result = "just now";
  }

  return result === "just now" ? result : `${result} ago`;
}
