export function useGetTimeZoneOffset(
  timezone = "Australia/Melbourne",
  date = new Date()
): number {
  // Standard UTC offsets without DST
  const standardOffsets = {
    "Australia/Sydney": 10,
    "Australia/Melbourne": 10,
    "Australia/Hobart": 10,
    "Australia/Brisbane": 10,
    "Australia/Adelaide": 9.5,
    "Australia/Darwin": 9.5,
    "Australia/Perth": 8,
    "Australia/Eucla": 8.75,
    "Australia/Lord_Howe": 10.5,
    // Other time zones can be added here
  };

  // DST adjustments
  const dstAdjustments = {
    "Australia/Sydney": 1,
    "Australia/Melbourne": 1,
    "Australia/Hobart": 1,
    "Australia/Adelaide": 0.5,
    "Australia/Lord_Howe": 0.5,
    // Other DST-adjusted time zones can be added here
  };

  let offset = standardOffsets[timezone] || 0;

  // Determine if DST is observed for the timezone
  if (dstAdjustments[timezone] && isDstObserved(timezone, date)) {
    offset += dstAdjustments[timezone];
  }

  return offset as number;
}

function isDstObserved(timezone, date) {
  const startDstDate = new Date(date.getFullYear(), 9); // October 1st
  const endDstDate = new Date(date.getFullYear() + 1, 3); // April 1st of the next year

  // Find the first Sunday in October
  startDstDate.setDate(1 + ((7 - startDstDate.getDay()) % 7));
  // Find the first Sunday in April
  endDstDate.setDate(1 + ((7 - endDstDate.getDay()) % 7));

  // Adjust for the specific transition time, if necessary
  // For example, DST might start at 2 AM local time
  // startDstDate.setHours(2);
  // endDstDate.setHours(2);

  // DST is observed between these two dates
  return date >= startDstDate && date < endDstDate;
}
