<template>
  <div
    class="flex gap-2.5 items-center transition-all"
    :class="{
      'border-accent-color': active,
      'hover:border-accent-color': !noHover,
      border: !noBorder,
      'py-2.5 px-x3 rounded-x2': !noPadding,
    }"
    @click="emit('select-member', member)"
  >
    <img
      v-if="useGetThumbnail(member?.image)"
      :src="useGetThumbnail(member?.image)"
      alt=""
      class="w-[45px] h-[45px] rounded-x2 object-cover"
    />
    <div
      v-else-if="
        useGetCustomerInitials(member)?.length &&
        !useGetThumbnail(member?.image)
      "
      class="flex rounded-x2 w-x8 h-x8 items-center justify-center bg-grey-500 text-sm"
    >
      <p class="text-white">{{ useGetCustomerInitials(member) }}</p>
    </div>
    <img
      v-else
      src="~/assets/img/default-user.png"
      alt=""
      class="w-x8 h-x8 bg-white rounded-x2"
    />

    <div class="flex flex-col justify-center">
      <p>{{ member.name }}</p>
      <p class="text-xs text-grey-500">{{ member.title }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Customer } from "~/types/customers";

const props = defineProps<{
  member: Customer;
  active?: boolean;
  noHover?: boolean;
  noBorder?: boolean;
  noPadding?: boolean;
}>();

const emit = defineEmits(["select-member"]);
</script>
